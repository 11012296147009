import NextErrorComponent, { ErrorProps } from 'next/error'
import { captureException, flush } from '@sentry/nextjs'
import { getIntlProps } from 'utils/intl'

type ErrorContext = Parameters<typeof NextErrorComponent['getInitialProps']>[0]
type SentryErrorProps = ErrorProps & {
  hasGetInitialPropsRun?: boolean
  err?: unknown
}

function AppError({
  statusCode,
  hasGetInitialPropsRun,
  err
}: SentryErrorProps) {
  if (!hasGetInitialPropsRun && err) {
    captureException(err)
  }

  return <NextErrorComponent statusCode={statusCode} />
}

AppError.getInitialProps = async (ctx: ErrorContext) => {
  const errorInitialProps: SentryErrorProps = await NextErrorComponent.getInitialProps(
    ctx
  )

  const intl = await getIntlProps({ locale: 'en' }, [])
  Object.assign(errorInitialProps, intl)

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true
  errorInitialProps.err = ctx.err

  // Returning early because we don't want to log 404 errors to Sentry.
  if (ctx.res?.statusCode === 404) {
    return errorInitialProps
  }

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (ctx.err) {
    captureException(ctx.err)

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await flush(2000)

    return errorInitialProps
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  captureException(
    new Error(`_error.js getInitialProps missing data at path: ${ctx.asPath}`)
  )
  await flush(2000)

  return errorInitialProps
}

export default AppError
